<template>
  <div>
    <v-dialog
      v-model="showDialog"
      persistent
      transition="terms-dialog dialog-right-transition"
      max-width="780"
    >
      <v-card height="430">
        <v-toolbar class="show-receipt" dense color="elevation-0">
          <v-btn icon color="black" @click.native="closeDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <div
          class="h-full d-flex flex-column align-center justify-space-between"
        >
          <h3>تم تحديث الشروط والأحكام</h3>
          <img
            src="~/assets/images/auth/manual-task-icon.svg"
            alt="manual-task-icon"
          >
          <p class="font-weight-bold">
            {{ message }}
          </p>
          <v-btn @click="goToTerms" text color="#8e6ba1" class="font-weight-bold"> الانتقال إلى الشروط والأحكام </v-btn>

          <div class="terms-btn">
            <v-btn
              width="106"
              class="accept-terms-btn font-weight-bold"
              rounded
              dark
              color="#09ce6b"
              @click="acceptTerms"
            >
              قبول
              <v-icon size="20" right>
                mdi-checkbox-marked-circle
              </v-icon>
            </v-btn>
            <v-btn
              @click="closeDialog"
              width="106"
              class="py-5 font-weight-bold"
              rounded
              outlined
              color="#f00"
            >
              إلغاء
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup>
import { ref, watch, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useSettingsStore } from '~/stores/SettingsStore';
const settingsStore = useSettingsStore();

const props = defineProps({
  dialogVisible: {
    type: Boolean,
    default: false
  },
  message: {
    type: String,
    default: ''
  }
});

const emit = defineEmits(['dialogClosed', 'acceptTerms']);

const router = useRouter();

const showDialog = ref(false);

watch(
  () => props.dialogVisible,
  (val) => {
    showDialog.value = val;
  }
);

const scheduledTasks = computed(() => settingsStore.scheduledTasks);

const closeDialog = () => {
  emit('dialogClosed');
};

const goToTerms = () => {
  router.push('/pages/terms-and-conditions-with-privacy-policy');
  emit('dialogClosed', true);
};

const acceptTerms = () => {
  emit('acceptTerms');
};
</script>

<style lang="scss">

  .show-receipt .v-toolbar__content {
    flex-direction: row-reverse !important;
  }

  .accept-terms-btn {
    height: 42.22px !important;
  }

</style>
